import React from "react";
import { Loader } from "../../Common/Loader";
import { Paragraph, Main, Heading, Button, Box } from "grommet";
import { useParams } from "react-router-dom";
import toNumber from "lodash/toNumber";
import { getProjectItem } from "../../../api/project";
import { ProjectDeleteLayer } from "./ProjectDeleteLayer";
import {
  Edit as IconEdit,
  Trash as IconTrash,
  Projects as IconProject,
} from "grommet-icons";
import { ProjectItemList } from "./Component/ProjectItemList";
import { PROJECT_STATUS_DELETED } from "../../../constants/globalConstants";
import { LinkButton } from "../Component/LinkButton";
import { useAsyncFn } from "../../../utils/hooks/useAsyncFn";

type ParamTypes = Partial<Record<"id", string>>;

export const ProjectDetailCard = () => {
  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const [open, setOpen] = React.useState<boolean>();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);

  const projectState = useAsyncFn(getProjectItem, numberId);

  if (isNaN(numberId)) {
    return (
      <Main pad="large">
        <Heading>Error loading project</Heading>
        <Paragraph>invalid project id.</Paragraph>
      </Main>
    );
  }

  return (
    <Main pad="large">
      {projectState !== undefined && (
        <>
          {projectState.id_state !== PROJECT_STATUS_DELETED && (
            <Box direction="row" align="start" gap="small">
              <LinkButton
                to={`/projects/edit/${projectState.id_project}`}
                state={{ name: projectState.name, remark: projectState.remark }}
              >
                <Button
                  secondary
                  icon={<IconEdit color="brand" />}
                  color="brand"
                  label="Edit project"
                />
              </LinkButton>
              <LinkButton
                to={`/projects/${projectState.id_project}/device/new`}
              >
                <Button
                  secondary
                  label="Create device"
                  color="brand"
                  icon={<IconProject color="brand" />}
                />
              </LinkButton>
              <Button
                secondary
                icon={<IconTrash color="buttonDelete" />}
                color="buttonDelete"
                label="Delete this project"
                onClick={onOpen}
              />
            </Box>
          )}
          <ProjectItemList productFields={projectState} />
          <Box
            direction="row"
            gap="medium"
            className="product-form-aria-button"
          >
            <LinkButton to={"/projects/"}>
              <Button secondary label="Back" color="brand" />
            </LinkButton>
          </Box>
          {open && (
            <ProjectDeleteLayer closeFunc={onClose} projectId={numberId} />
          )}
        </>
      )}
      {projectState === undefined && <Loader />}
    </Main>
  );
};

import React, { useEffect, useState } from "react";
import { TDeviceItem } from "../../models/Device";
import { Box, Heading, Paragraph, Button } from "grommet";
import { getDeviceInfo } from "../../api/device";
import { useParams } from "react-router-dom";
import { Page404 } from "../Common/Page404";
import { Loader } from "../Common/Loader";
import { DevicePageButtons } from "./components/DevicePageButtons";
import { LinkButton } from "../Projects/Component/LinkButton";

export function DevicePage() {
  const { idDevice, id } = useParams();
  const [device, setDevice] = useState<TDeviceItem>();
  const [errorFlag, setErrorFlag] = useState<boolean>();

  useEffect(() => {
    setErrorFlag(false);
    getDeviceInfo(Number(idDevice), Number(id)).then((response) => {
      response.error_text ? setErrorFlag(true) : setDevice(response);
    });
  }, [idDevice, id]);

  return (
    <Box fill pad="medium" margin="medium">
      {device ? (
        <>
          <DevicePageButtons
            name={device.name}
            remark={device.remark}
            id_device={device.id_device}
            id_project={device.id_project}
          />
          <Heading>{device.name}</Heading>
          <Paragraph>
            <b>Remark:</b> {device.remark}
          </Paragraph>
          <Paragraph>
            <b>Id device:</b> {device.id_device}
          </Paragraph>
          <Paragraph>
            <b>Id project:</b> {device.id_project}
          </Paragraph>
          <Paragraph>
            <b>Id state:</b> {device.id_state}
          </Paragraph>
          <Paragraph>
            <b>Date create:</b> {device.dset}
          </Paragraph>
          <Box
            direction="row"
            gap="medium"
            className="product-form-aria-button"
          >
            <LinkButton to={`/projects/${id}`}>
              <Button secondary label="Back" color="brand" />
            </LinkButton>
          </Box>
        </>
      ) : errorFlag ? (
        <Page404 />
      ) : (
        <Loader />
      )}
    </Box>
  );
}

import {
  TFormAddProject,
  TFormEditProject,
  TProjectItem,
} from "models/Project";
import { api } from "api/client";

export const getProjectList = async (): Promise<TProjectItem[]> => {
  return await api.send("GET", "/project/");
};

export const getProjectItem = async (
  projectId: number
): Promise<TProjectItem> => {
  return await api.send("GET", `/project/?id_project=${projectId}`);
};

export const addProjectItem = async (
  data: TFormAddProject
): Promise<TProjectItem> => {
  return await api.send("POST", "/project/", data);
};

export const editProjectItem = async (
  data: TFormEditProject
): Promise<TProjectItem> => {
  return await api.send("PUT", "/project/", data);
};

export const deleteProjectItem = async (
  projectId: number
): Promise<TProjectItem> => {
  return await api.send("DELETE", "/project/", { id_project: projectId });
};

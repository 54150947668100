import React, { FC, useState } from "react";

import { observer } from "mobx-react";
import { Box, Button, Grid, Text, Avatar } from "grommet";
import Logo from "assets/img/logo.png";
import { ThemeSwitchButton } from "../ThemeSwitch/ThemeSwitchButton";
import { MainSideBar } from "./MainSideBar";
import { MainHeaderSelector } from "./Selector/MainHeaderSelector";

export const NavigationList: FC = observer((props) => {
  const [sidebar, setSidebar] = useState(false);

  return (
    <>
      {Grid.available ? (
        <Grid
          rows={["auto", "flex", "auto"]}
          columns={["auto", "flex"]}
          areas={[
            ["header", "header"],
            ["sidebar", "main"],
            ["footer", "footer"],
          ]}
          className={"main-grid"}
        >
          <Box
            gridArea="header"
            className="header"
            direction="row"
            background="baseBackground"
            align="center"
            justify="between"
            pad={{ horizontal: "medium", vertical: "small" }}
          >
            <Button onClick={() => setSidebar(!sidebar)}>
              <Avatar src={Logo} />
            </Button>
            <MainHeaderSelector />
            <ThemeSwitchButton />
          </Box>
          {sidebar && <MainSideBar />}
          <Box gridArea="main">{props.children}</Box>
          <Box
            gridArea="footer"
            background="baseBackground"
            pad="small"
            justify="between"
            className="footer"
          >
            <Text textAlign="center" size="small">
              © 2022 Freematiq
            </Text>
          </Box>
        </Grid>
      ) : (
        <Text>Grid is not supported by your browser</Text>
      )}
    </>
  );
});

import React, { FC } from "react";
import { Form, FormField, Button, Box, TextInput, MaskedInput } from "grommet";
import { MailOption, Key } from "grommet-icons";
import { observer } from "mobx-react";
import { rootStore } from "store/RootStore";
import { HeaderComponent } from "../Common/Header";

type TLoginFormData = {
  email: string;
  password: string;
};

export const LoginComponent: FC = observer(() => {
  const handleSubmitLoginForm = (formData: TLoginFormData): void => {
    rootStore.authStore.login(formData);
  };

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div>
        <Box fill align="center" justify="center">
          <Box width="medium" margin="large">
            <Form
              name="basic"
              onSubmit={(event) => {
                handleSubmitLoginForm(event.value as TLoginFormData);
              }}
              className="form"
            >
              <FormField
                label="Email"
                name="email"
                required
                className="form__input"
              >
                <MaskedInput
                  reverse
                  icon={<MailOption />}
                  name="email"
                  type="email"
                />
              </FormField>
              <FormField
                label="Password"
                name="password"
                required
                className="form__input"
              >
                <TextInput
                  reverse
                  name="password"
                  type="password"
                  icon={<Key />}
                />
              </FormField>
              <Box direction="row" justify="between" margin={{ top: "medium" }}>
                <Button primary type="submit" justify="center" label="Login" />
              </Box>
            </Form>
          </Box>
        </Box>
      </div>
    </div>
  );
});

import React from "react";
import { Box, Text } from "grommet";
import { ThemeSwitchButton } from "../ThemeSwitch/ThemeSwitchButton";

export function HeaderComponent() {
  return (
    <header>
      <Box direction="row" align="center" justify="center">
        <Text size="3xl">KORD</Text>
        <ThemeSwitchButton />
      </Box>
    </header>
  );
}

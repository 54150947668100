import React from "react";
import { Box, Spinner } from "grommet";

export function HeaderSelectorLoader() {
  return (
    <Box align="center" margin="xsmall">
      <Spinner size="medium" />
    </Box>
  );
}

import {
  TDeviceItem,
  TDeviceSoftwarecode,
  TDeviceType,
  TFormCreateDevice,
  TFormEditDevice,
} from "models/Device";
import { api } from "api/client";

export const getDeviceTypeList = async (): Promise<TDeviceType> => {
  return await api.send("GET", "/device/type/");
};

export const getDeviceListInProject = async (
  projectId: number
): Promise<TDeviceItem[]> => {
  return await api.send("GET", `/device/?id_project=${projectId}`);
};

export const getDeviceInfo = async (
  deviceId: number,
  projectId: number
): Promise<TDeviceItem> => {
  return await api.send(
    "GET",
    `/device/?id_device=${deviceId}&id_project=${projectId}`
  );
};

export const createDeviceItemInProject = async (
  data: TFormCreateDevice
): Promise<TDeviceItem> => {
  return await api.send("POST", "/device/", data);
};

export const editDeviceItem = async (
  data: TFormEditDevice
): Promise<TDeviceItem> => {
  return await api.send("PUT", "/device/", data);
};

export const deleteDeviceItem = async (
  deviceId: number
): Promise<TDeviceItem> => {
  return await api.send("DELETE", "/device/", {
    id_device: deviceId,
  });
};

export const getDeviceSoftwarecode = async (
  deviceId: string
): Promise<TDeviceSoftwarecode> => {
  return await api.send("GET", `/device/softwarecode/?id_device=${deviceId}`);
};

export const changeDeviceSoftwarecode = async (
  softwareId: number | undefined,
  code?: string | undefined
) => {
  return await api.send("PUT", "/device/softwarecode/", {
    id_softwarecode: softwareId,
    code: code,
  });
};

export const createSoftwareCode = async (
  deviceId: number
): Promise<TDeviceSoftwarecode> => {
  return await api.send("POST", "/device/softwarecode/", {
    id_device: deviceId,
    code: "",
    id_state: 0,
  });
};

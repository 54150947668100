import { makeAutoObservable } from "mobx";
import { TDeviceItem } from "../models/Device";
import { getDeviceListInProject } from "../api/device";
import { errorHandler } from "../utils/errorHandler";

class DeviceStore {
  loading = false;
  devices: TDeviceItem[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  setDevices(devices: TDeviceItem[]) {
    this.devices = devices;
    this.loading = false;
  }

  getDevices = async (id: number) => {
    try {
      this.loading = true;
      const result = await getDeviceListInProject(id);
      this.setDevices(result);
    } catch (err: unknown) {
      errorHandler(err);
    }
  };
}

export const deviceStore = new DeviceStore();

import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box, Button, Heading, Layer, Text } from "grommet";
import { TDeleteLayer } from "../../Devices/components/DeleteLayer";
import { deleteProjectItem } from "../../../api/project";
import { projectStore } from "../../../store/ProjectsStore";
import { PROJECT_STATUS_DELETED } from "../../../constants/globalConstants";

export function ProjectDeleteLayer(props: TDeleteLayer) {
  const navigate = useNavigate();
  const deleteDevice = () => {
    deleteProjectItem(Number(props.projectId)).then((response) => {
      if (response.id_state === PROJECT_STATUS_DELETED) {
        projectStore.getProjects();
        toast.success("Project was deleted");
        navigate("/projects");
      }
    });
  };
  return (
    <Layer>
      <Box
        pad="medium"
        gap="small"
        width="medium"
        background="backgroundSideBar"
      >
        <Heading level={3} margin="none">
          Confirm
        </Heading>
        <Text>Are you sure you want to delete?</Text>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button label="Close" color="dark-3" onClick={props.closeFunc} />
          <Button
            onClick={deleteDevice}
            label={
              <Text color="white">
                <strong>Delete</strong>
              </Text>
            }
            primary
            color="status-critical"
          />
        </Box>
      </Box>
    </Layer>
  );
}

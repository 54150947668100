import { makeAutoObservable } from "mobx";
import { TUnitType } from "../models/Units";
import { getUnitList } from "../api/units";
import { errorHandler } from "../utils/errorHandler";

class UnitStore {
  loading = false;
  units: TUnitType[] = [];

  constructor() {
    makeAutoObservable(this);
  }

  getUnits = async (id: number) => {
    try {
      this.loading = true;
      const result = await getUnitList(id);
      this.setUnits(result);
    } catch (err) {
      errorHandler(err);
    }
  };

  setUnits(units: TUnitType[]) {
    this.units = units;
    this.loading = false;
  }
}

export const unitStore = new UnitStore();

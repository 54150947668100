import React from "react";
import ReactDOM from "react-dom";
import App from "App";

import "assets/styles/index.scss";

ReactDOM.render(
    <App />,
    document.getElementById("root")
);

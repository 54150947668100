import { TUnitNewPartData, TUnitPartNewData, TUnitType } from "../models/Units";
import { api } from "./client";

export const getUnitList = async (deviceId: number): Promise<TUnitType[]> => {
  return await api.send("GET", `/device/unit/?id_device=${deviceId}`);
};

export const changeUnit = async (
  DeviceNewData: TUnitPartNewData
): Promise<TUnitType> => {
  return await api.send("PUT", "/device/unit", DeviceNewData);
};

export const addNewUnit = async (
  newDeviceData: TUnitNewPartData
): Promise<TUnitType> => {
  return await api.send("POST", "/device/unit", newDeviceData);
};

export const deleteUnit = async (unitId: number): Promise<TUnitType> => {
  return await api.send("DELETE", `/device/unit/?id_unit=${unitId}`);
};

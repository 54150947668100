import React from "react";
import { Login } from "containers/Auth/Login";
import { Main } from "containers/Main/Main";
import { ProjectsTable } from "./components/Projects/ProjectsTable";
import { DevicePage } from "./components/Devices/DevicePage";
import { DeviceEdit } from "./components/Devices/DeviceEdit";
import { Page404 } from "components/Common/Page404";
import { ProjectDetailCard } from "./components/Projects/ProjectDetailCard/ProjectDetailCard";
import { ProjectForm } from "./components/Projects/ProjectForm/ProjectForm";
import { MainCodeEditor } from "./components/Editor/CodeEditor";
import { DeviceCreatePage } from "./components/Devices/DeviceCreatePage";

export type TRoute = {
  path: string;
  component: React.ComponentType;
  isOuter?: boolean;
};

export type TRoutes = TRoute[];

export const routes: TRoutes = [
  { path: "/", component: Main },
  { path: "/login", component: Login, isOuter: true },
  { path: "/projects", component: ProjectsTable },
  { path: "/projects/:id", component: ProjectDetailCard },
  { path: "/projects/new", component: ProjectForm },
  { path: "/projects/edit/:id", component: ProjectForm },
  { path: "/projects/:idProject/device/new", component: DeviceCreatePage },
  { path: "/projects/:idProject/device/:idDevice", component: DevicePage },
  { path: "/projects/:idProject/device/:idDevice/edit", component: DeviceEdit },
  {
    path: "/projects/:idProject/device/:idDevice/code",
    component: MainCodeEditor,
  },
  { path: "*", component: Page404 },
];

import React, { useState } from "react";
import {
  Heading,
  Main,
  TextInput,
  Button,
  Box,
  Form,
  FormField,
} from "grommet";
import { useNavigate, useParams } from "react-router-dom";
import toNumber from "lodash/toNumber";
import { TFormAddProject } from "../../../models/Project";
import { addProjectItem, editProjectItem } from "../../../api/project";
import { useLocation } from "react-router-dom";
import { LinkButton } from "../Component/LinkButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { projectStore } from "../../../store/ProjectsStore";

type ParamTypes = Partial<Record<"id", string>>;

export const ProjectForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialFormValue: TFormAddProject = location.state as TFormAddProject;
  const [value, setValue] = useState<TFormAddProject | undefined>(
    initialFormValue
  );

  const { id } = useParams<ParamTypes>();
  const numberId = toNumber(id);

  const handleAddProject = (formValue: TFormAddProject) => {
    addProjectItem(formValue).then((response) => {
      if (response.id_project) {
        projectStore.getProjects();
        toast.success("Project created successfully");
        navigate(`/projects/${response.id_project}`);
      } else {
        toast.error("An error occurred while adding the project");
      }
    });
  };
  const handleEditProject = (formValue: TFormAddProject) => {
    editProjectItem({
      id_project: numberId,
      name: formValue.name,
      remark: formValue.remark,
    }).then((response) => {
      if (response.id_project) {
        projectStore.getProjects();
        toast.success("Project changed successfully");
        navigate(`/projects/${response.id_project}`);
      } else {
        toast.error("An error occurred while editing the project");
      }
    });
  };

  return (
    <Main pad="large">
      <Heading>
        {numberId
          ? `Edit "${initialFormValue.name}" project`
          : "Create a new project"}
      </Heading>
      <Box fill align="start" justify="start">
        <Box width="large">
          <Form
            value={value}
            validate="submit"
            onChange={(nextValue) => setValue(nextValue)}
            onSubmit={({ value }) =>
              numberId ? handleEditProject(value) : handleAddProject(value)
            }
          >
            <FormField
              name="name"
              htmlFor="text-input-name"
              label="Name"
              required
            >
              <TextInput id="text-input-name" name="name" />
            </FormField>
            <FormField
              name="remark"
              htmlFor="text-input-remark"
              label="Remark"
              required
            >
              <TextInput id="text-input-remark" name="remark" />
            </FormField>
            <Box
              direction="row"
              gap="medium"
              className="product-form-aria-button"
            >
              <Button
                type="submit"
                primary
                label={numberId ? "Save change project" : "Create project"}
              />
              <LinkButton
                to={numberId ? `/projects/${numberId}` : "/projects/"}
              >
                <Button type="button" label="Cancel" color="brand" />
              </LinkButton>
            </Box>
          </Form>
        </Box>
      </Box>
    </Main>
  );
};

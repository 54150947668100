import React, { useState } from "react";
import { Box, Select } from "grommet";
import { observer } from "mobx-react";
import { TProjectItem } from "../../../models/Project";
import { TDeviceItem } from "../../../models/Device";
import { TUnitType } from "../../../models/Units";

type SelectProps = {
  placeholder: string;
  labelKey: string;
  valueKey: string;
  options: Array<TProjectItem | TDeviceItem | TUnitType>;
  value?: string;
  setState: (v: string) => void;
  getOptions?: { (id: number): void };
  default?: number;
};

export const HeaderSelect = observer((props: SelectProps) => {
  const [options, setOptions] = useState(props.options);
  return (
    <Box background="backgroundSideBar" round="xsmall" margin="small">
      <Select
        placeholder={props.placeholder}
        labelKey={props.labelKey}
        dropHeight={"medium"}
        defaultValue={props.default}
        valueKey={{ key: props.valueKey, reduce: true }}
        value={props.value}
        options={options}
        onClose={() => setOptions(props.options)}
        onSearch={(text) => {
          const exp = new RegExp(text, "i");
          setOptions(props.options.filter((o) => exp.test(o.name)));
        }}
        onChange={({ value: nextValue }) => {
          props.setState(nextValue);
          props.getOptions?.(nextValue);
        }}
      />
    </Box>
  );
});

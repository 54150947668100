import React, { FC } from "react";
import { Route, Routes, Outlet } from "react-router-dom";
import { LayoutForInnerRoute } from "./layouts/LayoutForInnerRoute";
import { ProjectsTable } from "./components/Projects/ProjectsTable";
import { ProjectDetailCard } from "./components/Projects/ProjectDetailCard/ProjectDetailCard";
import { ProjectForm } from "./components/Projects/ProjectForm/ProjectForm";
import { DevicePage } from "./components/Devices/DevicePage";
import { DeviceCreatePage } from "./components/Devices/DeviceCreatePage";
import { DeviceEdit } from "./components/Devices/DeviceEdit";
import { MainCodeEditor } from "./components/Editor/CodeEditor";
import { Page404 } from "./components/Common/Page404";

export const AllRoutesRender: FC = () => {
  return (
    <Routes>
      <Route path="/" element={<LayoutForInnerRoute />}>
        <Route path="/projects" element={<Outlet />}>
          <Route path=":id" element={<Outlet />}>
            <Route index element={<ProjectDetailCard />} />
            <Route path="device/" element={<Outlet />}>
              <Route path=":idDevice/" element={<Outlet />}>
                <Route path="edit/" element={<DeviceEdit />} />
                <Route path="code/" element={<MainCodeEditor />} />
                <Route index element={<DevicePage />} />
              </Route>
              <Route path={"new/"} element={<DeviceCreatePage />} />Ï
            </Route>
          </Route>
          <Route path="new" element={<ProjectForm />} />
          <Route path="edit/:id" element={<ProjectForm />} />
          <Route index element={<ProjectsTable />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Route>
    </Routes>
  );
};

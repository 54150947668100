export const theme = {
  global: {
    colors: {
      brand: {
        dark: "#4f1b77",
        light: "#005d8f",
      },
      baseBackground: {
        dark: "#7700cc",
        light: "#50a1e3",
      },
      buttonDelete: {
        dark: "#F08080",
        light: "#F08080",
      },
      background: {
        dark: "#111111",
        light: "#FFFFFF",
      },
      backgroundSideBar: {
        dark: "#2d2d2d",
        light: "#efefef",
      },
      backgroundCardHover: {
        dark: "#6FFFB0",
        light: "#005d8f",
      },
      "background-back": {
        dark: "#222222",
        light: "#EEEEEE",
      },
      "background-front": {
        dark: "#222222",
        light: "#FFFFFF",
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111",
      },
      text: {
        dark: "#EEEEEE",
        light: "#333333",
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000",
      },
      "text-weak": {
        dark: "#CCCCCC",
        light: "#444444",
      },
      "text-xweak": {
        dark: "#999999",
        light: "#666666",
      },
      border: {
        dark: "#444444",
        light: "#CCCCCC",
      },
    },
    font: {
      family: `-apple-system,
         BlinkMacSystemFont, 
         "Segoe UI", 
         Roboto, 
         Oxygen, 
         Ubuntu, 
         Cantarell, 
         "Fira Sans", 
         "Droid Sans",  
         "Helvetica Neue", 
         Arial, sans-serif,  
         "Apple Color Emoji", 
         "Segoe UI Emoji", 
         "Segoe UI Symbol"`,
    },

    layer: {
      background: {
        dark: "#111111",
        light: "#FFFFFF",
      },
    },
  },
};

import React, { useEffect, useState } from "react";
import { Box, Heading, Form, FormField, TextInput, Button } from "grommet";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { deviceStore } from "../../store/DeviceStore";
import { TDeviceButtons } from "./components/DevicePageButtons";
import { editDeviceItem, getDeviceInfo } from "../../api/device";
import { Page404 } from "../Common/Page404";
import { Loader } from "../Common/Loader";
import { toast } from "react-toastify";
import { LinkButton } from "../Projects/Component/LinkButton";

type TDeviceChangeProps = {
  name: string;
  remark: string;
};

export function DeviceEdit() {
  const location = useLocation();
  const navigate = useNavigate();
  const { idDevice, id } = useParams();
  const [errorFlag, setErrorFlag] = useState<boolean>();
  const [defaultValues, setDefaultValues] = useState<TDeviceButtons>();

  const saveChanges = (event: { value: TDeviceChangeProps }) => {
    editDeviceItem({
      id_device: Number(idDevice),
      name: event.value.name || String(defaultValues?.name),
      remark: event.value.remark || String(defaultValues?.remark),
    }).then((response) => {
      if (!response.error_text && defaultValues) {
        deviceStore.getDevices(defaultValues.id_project);
        toast.success("Changes saved");
        navigate(`/projects/${id}/device/${idDevice}`);
      } else {
        toast.error(response.error_text);
      }
    });
  };

  useEffect(() => {
    !location.state
      ? getDeviceInfo(Number(idDevice), Number(id)).then((response) => {
          response.error_text ? setErrorFlag(true) : setDefaultValues(response);
        })
      : setDefaultValues(location.state as TDeviceButtons);
  }, [idDevice, id, location.state]);

  return (
    <Box pad="small" margin="medium">
      {defaultValues ? (
        <>
          <Heading>Edit {defaultValues.name} device</Heading>
          <Box align="start">
            <Form onSubmit={saveChanges}>
              <FormField name="name" label="Name">
                <TextInput
                  name="name"
                  defaultValue={defaultValues.name}
                  required
                />
              </FormField>
              <FormField name="remark" label="Remark">
                <TextInput
                  name="remark"
                  defaultValue={defaultValues.remark}
                  required
                />
              </FormField>
              <Box direction="row-responsive" gap="small">
                <Button primary type="submit" label="Save" />
                <LinkButton to={`/projects/${id}/device/${idDevice}`}>
                  <Button label="Cancel" />
                </LinkButton>
              </Box>
            </Form>
          </Box>
        </>
      ) : errorFlag ? (
        <Page404 />
      ) : (
        <Loader />
      )}
    </Box>
  );
}

import React from "react";
import { Box, Button } from "grommet";
import {
  Code as IconCode,
  Edit as IconEdit,
  Trash as IconTrash,
} from "grommet-icons/icons";
import { LinkButton } from "../../Projects/Component/LinkButton";
import { DeleteLayer } from "./DeleteLayer";
import { TDeviceSoftwarecode } from "../../../models/Device";
import { useNavigate } from "react-router-dom";

export type TDeviceButtons = {
  id_device: number;
  id_project: number;
  name: string;
  remark: string;
  softwareCode?: TDeviceSoftwarecode;
};

export function DevicePageButtons(props: TDeviceButtons) {
  const [open, setOpen] = React.useState<boolean>();
  const onOpen = () => setOpen(true);
  const onClose = () => setOpen(undefined);
  const navigate = useNavigate();

  const editorLink = () => {
    navigate(`/projects/${props.id_project}/device/${props.id_device}/code/`);
  };

  return (
    <>
      <Box gap="small" direction="row">
        <LinkButton
          to={`/projects/${props.id_project}/device/${props.id_device}/edit`}
          state={{
            name: props.name,
            remark: props.remark,
            id_project: props.id_project,
          }}
        >
          <Button
            secondary
            icon={<IconEdit color="brand" />}
            color="brand"
            label="Edit device"
          />
        </LinkButton>
        <Button
          secondary
          onClick={editorLink}
          icon={<IconCode color="brand" />}
          color="brand"
          label="Code"
        />
        <Button
          secondary
          icon={<IconTrash color="buttonDelete" />}
          color="buttonDelete"
          onClick={onOpen}
          label="Delete this device"
        />
      </Box>
      {open && <DeleteLayer closeFunc={onClose} />}
    </>
  );
}

import { themeStore } from "../../store/ThemeStore";
import MonacoEditor from "@monaco-editor/react";
import React from "react";
import styled from "styled-components";

export const Editor = styled(MonacoEditor)`
  position: absolute;
  height: 100%;
  width: 100%;
`;

type EditorProps = {
  deviceCode: string | undefined;
  readOnly: boolean;
  setDeviceCode: (v?: string) => void;
};

export function CodeEditor(props: EditorProps) {
  return (
    <Editor
      defaultValue={props.deviceCode}
      options={{
        fontSize: 18,
        automaticLayout: true,
        readOnly: props.readOnly,
      }}
      theme={themeStore.themeMode === "dark" ? "vs-dark" : "vs-light"}
      onChange={(text) => props.setDeviceCode(text)}
    />
  );
}

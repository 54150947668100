import React from "react";
import { Paragraph, Heading } from "grommet";
import { TProjectItem } from "../../../../models/Project";

type Props = {
  productFields: TProjectItem
}

export const ProjectItemList = (( { productFields }: Props) => {
  return (
    <>
      <Heading>{productFields.name}</Heading>
      <Paragraph>
        <b>Id project:</b> {productFields.id_project}
      </Paragraph>
      <Paragraph>
        <b>Id state:</b> {productFields.id_state}
      </Paragraph>
      <Paragraph>
        <b>Name:</b> {productFields.name}
      </Paragraph>
      <Paragraph>
        <b>Remark:</b> {productFields.remark}
      </Paragraph>
      <Paragraph>
        <b>Date create:</b> {new Date(productFields.dset).toLocaleString()}
      </Paragraph>
    </>
  )
});

import React from "react";
import { Box, Heading, Form, FormField, TextInput, Button } from "grommet";
import {
  createDeviceItemInProject,
  createSoftwareCode,
} from "../../api/device";
import { useParams } from "react-router-dom";
import { deviceStore } from "../../store/DeviceStore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type TCreateDeviceEvenet = {
  value: {
    name: string;
    remark: string;
  };
};

export function DeviceCreatePage() {
  const navigate = useNavigate();
  const { id } = useParams();

  const createDevice = (event: TCreateDeviceEvenet) => {
    id &&
      createDeviceItemInProject({
        id_project: Number(id),
        name: event.value.name,
        remark: event.value.remark,
      }).then((response) => {
        if (!response.error_text) {
          createSoftwareCode(response.id_device);
          deviceStore.getDevices(Number(id));
          toast.success(`Success created ${response.name ?? ""} device`);
          navigate(`/projects/${id}/device/${response.id_device ?? ""}`);
        } else {
          toast.error(response.error_text);
        }
      });
  };
  return (
    <Box fill pad="medium" margin="medium">
      <Heading>Create device</Heading>
      <Form onSubmit={createDevice}>
        <Box width="large" margin={{ bottom: "large" }}>
          <FormField name="name" label="Name">
            <TextInput required name="name" />
          </FormField>
          <FormField name="remark" label="Remark">
            <TextInput name="remark" />
          </FormField>
        </Box>
        <Button primary type="submit" label="Create" />
        <Button
          label="Cancel"
          margin={{ left: "medium" }}
          onClick={() => navigate(`/projects/${id}`)}
        />
      </Form>
    </Box>
  );
}

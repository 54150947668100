import React from "react";
import { TProjectItem } from "../../models/Project";
import { Card, CardFooter, CardHeader, CardBody, Text } from "grommet";
import { parseISO } from "date-fns";
import { PROJECT_STATUS_DELETED } from "../../constants/globalConstants";
import { LinkButton } from "./Component/LinkButton";

export function ProjectCard(props: { project: TProjectItem }) {
  return (
    <LinkButton to={`/projects/${props.project.id_project}`}>
      <Card
        key={props.project.id_project}
        pad="medium"
        gap="medium"
        className={
          props.project.id_state === PROJECT_STATUS_DELETED
            ? "background-card-deleted"
            : ""
        }
        background={
          props.project.id_state === PROJECT_STATUS_DELETED
            ? "backgroundSideBar"
            : "baseBackground"
        }
        onClick={() => false}
        hoverIndicator={"backgroundCardHover"}
      >
        <CardHeader align="center" justify="start">
          <Text size="medium" weight="bold">
            {props.project.name}
          </Text>
        </CardHeader>
        <CardBody align="start" justify="start">
          <Text size="small" weight="normal">
            {props.project.remark}
          </Text>
        </CardBody>
        <CardFooter align="center" justify="start">
          <Text size="small" weight="normal">
            {parseISO(props.project.dset).toDateString()}
          </Text>
        </CardFooter>
      </Card>
    </LinkButton>
  );
}

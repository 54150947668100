import React from "react";
import { Box } from "grommet";
import { rootStore } from "../../store/RootStore";
import { useNavigate } from "react-router-dom";
import { MainSideBarButton } from "./MainSideBarButton";
import {
  Home as IconHome,
  Projects as IconProjects,
  Logout as IconLogout,
} from "grommet-icons";

export function MainSideBar() {
  const navigate = useNavigate();
  const handleLogout = () => {
    rootStore.authStore.logout();
    rootStore.currentUserStore.clearCurrentUserData();
    navigate("/login");
  };
  return (
    <Box
      gridArea="sidebar"
      background="backgroundSideBar"
      width="small"
      animation={[
        { type: "fadeIn", duration: 300 },
        { type: "slideRight", size: "medium", duration: 150 },
      ]}
    >
      <MainSideBarButton icon={<IconHome />} name={"Main"} link={"/"} />
      <MainSideBarButton
        icon={<IconProjects />}
        name={"Projects"}
        link={"/projects"}
      />
      <MainSideBarButton
        icon={<IconLogout />}
        name={"Logout"}
        callback={handleLogout}
      />
    </Box>
  );
}

import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Loader } from "../Common/Loader";
import { toast } from "react-toastify";
import {
  getDeviceSoftwarecode,
  changeDeviceSoftwarecode,
} from "../../api/device";

import {
  Save as IconSave,
  Lock as IconLock,
  Unlock as IconUnlock,
} from "grommet-icons";
import { useParams } from "react-router-dom";
import { Box, Button } from "grommet";
import { CodeEditor } from "./Editor";
import { Page404 } from "../Common/Page404";

export const MainCodeEditor: FC = observer(() => {
  const { idDevice } = useParams();
  const [softwareCodeId, setSoftwareCodeId] = useState<number | undefined>();
  const [deviceCode, setDeviceCode] = useState<string | undefined>();
  const [readOnly, setReadOnly] = useState<boolean>(false);

  const [errorFlag, setErrorFlag] = useState(false);
  const saveCode = () =>
    changeDeviceSoftwarecode(softwareCodeId, deviceCode).then((response) => {
      if (response) {
        toast.success("Saved successfully");
      } else {
        toast.error("Save error");
      }
    });

  useEffect(() => {
    idDevice &&
      getDeviceSoftwarecode(idDevice).then((response) => {
        response.error_text ? setErrorFlag(true) : setDeviceCode(response.code);
        setSoftwareCodeId(response.id_softwarecode);
      });
  }, [idDevice]);

  return (
    <Box
      fill="vertical"
      margin="medium"
      border={true}
      round={{ size: "xsmall", corner: "top" }}
    >
      {softwareCodeId ? (
        <>
          <Box
            round={{ size: "xsmall", corner: "top" }}
            direction="row"
            background="backgroundSideBar"
            border={{ side: "bottom" }}
          >
            <Button
              tip="Save code"
              onClick={saveCode}
              icon={<IconSave />}
              margin="xsmall"
            />
            <Box
              fill
              align="center"
              justify="end"
              direction="row"
              width="small"
            >
              {readOnly ? (
                <Button
                  tip="Unlock edit"
                  onClick={() => setReadOnly(false)}
                  icon={<IconLock />}
                />
              ) : (
                <Button
                  tip="Lock edit"
                  onClick={() => setReadOnly(true)}
                  icon={<IconUnlock />}
                />
              )}
            </Box>
          </Box>
          <CodeEditor
            deviceCode={deviceCode}
            readOnly={readOnly}
            setDeviceCode={setDeviceCode}
          />
        </>
      ) : errorFlag ? (
        <Page404 />
      ) : (
        <Loader />
      )}
    </Box>
  );
});

import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { projectStore } from "../../../store/ProjectsStore";
import { deviceStore } from "../../../store/DeviceStore";
import { unitStore } from "../../../store/UnitStore";
import { HeaderSelectorLoader } from "./HeaderSelectorLoader";
import { HeaderSelect } from "./HeaderSelect";
import { Box } from "grommet";
import { useNavigate, useParams } from "react-router-dom";
import { PROJECT_STATUS_DELETED } from "../../../constants/globalConstants";

export const MainHeaderSelector = observer(() => {
  const [project, setProject] = useState<string | undefined>();
  const [device, setDevice] = useState<string | undefined>();
  const [unit, setUnit] = useState<string | undefined>();
  const { id, idDevice } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    projectStore.getProjects();
    id && !Number.isFinite(id) && deviceStore.getDevices(Number(id));
  }, [id, idDevice]);

  const setDeviceProject = (id: string) => {
    setDevice(undefined);
    setProject(id);
    navigate(`/projects/${id}`);
  };

  const goToDeviceCode = (id: string) => {
    setDevice(id);
    navigate(`/projects/${project}/device/${id}`);
  };

  return (
    <Box fill direction="row-responsive" align="start">
      {!projectStore.loading && projectStore.projects ? (
        <HeaderSelect
          placeholder="Projects"
          labelKey="name"
          default={Number(id)}
          valueKey="id_project"
          value={project}
          options={projectStore.projects.filter((project) => {
            return project.id_state !== PROJECT_STATUS_DELETED;
          })}
          setState={setDeviceProject}
          getOptions={Number.isFinite(id) ? void "" : deviceStore.getDevices}
        />
      ) : (
        <HeaderSelectorLoader />
      )}
      {!!id &&
        !Number.isFinite(id) &&
        (deviceStore.loading ? (
          <HeaderSelectorLoader />
        ) : (
          <HeaderSelect
            placeholder="Device"
            labelKey="name"
            default={Number(idDevice)}
            valueKey="id_device"
            value={device}
            options={deviceStore.devices.filter((device) => {
              return device.id_state !== 3;
            })}
            setState={goToDeviceCode}
            getOptions={unitStore.getUnits}
          />
        ))}
      {!!idDevice &&
        !Number.isFinite(idDevice) &&
        deviceStore.devices.length > 0 &&
        (unitStore.loading ? (
          <HeaderSelectorLoader />
        ) : (
          <HeaderSelect
            placeholder="Unit"
            labelKey="name"
            valueKey="id_unit"
            value={unit}
            options={unitStore.units}
            setState={setUnit}
          />
        ))}
    </Box>
  );
});

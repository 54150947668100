import React from "react";
import { Box, Layer, Button, Heading, Text } from "grommet";
import { deleteDeviceItem } from "../../../api/device";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { deviceStore } from "../../../store/DeviceStore";
import { PROJECT_STATUS_DELETED } from "../../../constants/globalConstants";

export type TDeleteLayer = {
  closeFunc: () => void;
  projectId?: number;
};

export function DeleteLayer(props: TDeleteLayer) {
  const { idDevice, id } = useParams();
  const navigate = useNavigate();

  const deleteDevice = () => {
    deleteDeviceItem(Number(idDevice)).then((response) => {
      if (response.id_state === PROJECT_STATUS_DELETED) {
        deviceStore.getDevices(Number(id));
        toast.success("Device was deleted");
        navigate(`/projects/${id}`);
      }
    });
  };
  return (
    <Layer>
      <Box
        pad="medium"
        gap="small"
        width="medium"
        background="backgroundSideBar"
      >
        <Heading level={3} margin="none">
          Confirm
        </Heading>
        <Text>Are you sure you want to delete?</Text>
        <Box
          as="footer"
          gap="small"
          direction="row"
          align="center"
          justify="end"
          pad={{ top: "medium", bottom: "small" }}
        >
          <Button label="Close" color="dark-3" onClick={props.closeFunc} />
          <Button
            onClick={deleteDevice}
            label={
              <Text color="white">
                <strong>Delete</strong>
              </Text>
            }
            primary
            color="status-critical"
          />
        </Box>
      </Box>
    </Layer>
  );
}

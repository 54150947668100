import React from "react";
import { observer } from "mobx-react";
import { Loader } from "../Common/Loader";
import { projectStore } from "../../store/ProjectsStore";
import { Box, Text, Grid, Button } from "grommet";
import { Package as IconPackage } from "grommet-icons";
import { ProjectCard } from "./ProjectCard";
import { toJS } from "mobx";
import { Add } from "grommet-icons";
import { LinkButton } from "./Component/LinkButton";

export const ProjectsTable = observer(() => {
  if (projectStore.loading) {
    return <Loader />;
  } else {
    return projectStore.projects ? (
      <Box pad="large">
        <Box
          direction="row"
          align="start"
          gap="small"
          className="product-table-nav"
        >
          <LinkButton to={"/projects/new"} state={{ name: "", remark: "" }}>
            <Button primary icon={<Add />} label="Create a new project" />
          </LinkButton>
        </Box>
        <Grid columns="medium" gap="small" className={"main"}>
          {projectStore.projects.map((project) => (
            <ProjectCard project={toJS(project)} key={project.id_project} />
          ))}
        </Grid>
      </Box>
    ) : (
      <Box align="center" justify="center" margin="xlarge">
        <IconPackage />
        <Text>No projects</Text>
      </Box>
    );
  }
});
